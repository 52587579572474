import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import UploadButton from "../components/UploadButton";
import Download from "./Download";
import Share from "./Share";
import WatermarkImage from '../images/watermark.png';
import communication from '../utils/communication';
import { trackAction } from "../utils/tracking";
import { EVENT_CATEGORY, EVENT_ACTION } from "../utils/mappings";

export const Photo = ({ photo }) => {
  const canvas = useRef(null);
  const editorContainer = useRef(null);
  const canvasContainer = useRef(null);
  const originalPhotoCanvas = useRef();
  const [img] = useState(new Image());
  const [currentEdit, setCurrentEdit] =useState(null);
  const [watermark] = useState(new Image());
  
  watermark.src = WatermarkImage;

  useEffect(() => {
    /**
     * Renders all applied filters every time any option is changed
     */
    renderFilters(canvas.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photo.filter, photo.properties]);

  useEffect(() => {
    if (photo.url) handleUpload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photo.url]);

  const showOriginal = () => {
    canvas.current.style.display = "none";
    trackAction(EVENT_CATEGORY.IMAGE, EVENT_ACTION.CLICK_SEE_ORIGINAL);
  };

  const returnState = () => {
    canvas.current.style.display = "block";
  };

  const handleUpload = () => {
    const ctx = canvas.current.getContext("2d");
    img.src = photo.url;
    img.crossOrigin = "anonymous";
    img.onload = function () {
      const newSize = resizeCanvasContainer(img.width, img.height);
      
      canvas.current.width = newSize.width;
      canvas.current.height = newSize.height;
      originalPhotoCanvas.current.width = newSize.width;
      originalPhotoCanvas.current.height = newSize.height;
      ctx.drawImage(img, 0, 0, newSize.width, newSize.height);
      
      originalPhotoCanvas.current.getContext("2d")
        .drawImage(img, 0, 0, newSize.width, newSize.height);

      // const computedCanvasSize = getComputedStyle(canvas.current);
      // const heightToWidthRadio = img.width / img.height;
      // canvas.current.height = +computedCanvasSize.height.replace('px', '');
      // canvas.current.width = canvas.current.height * heightToWidthRadio;
      // ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.current.width, canvas.current.height)

      canvas.current.removeAttribute("data-caman-id");
      window.onresize = () => resizeCanvasContainer(img.width, img.height);
    };
  };

  const resizeCanvasContainer = (imgWidth, imgHeight) => {
    const maxWidth = editorContainer.current.getBoundingClientRect().width; // max-width: 100%;
    const maxHeight = 0.6 * window.innerHeight; // max-height: 60vh;
    let ratio = 1.0;
    let width = imgWidth;
    let height = imgHeight;

    // Adjust to width
      ratio = maxWidth / width;
      height = height * ratio;
      width = width * ratio;
    
    // Adjust to height
    if (height > maxHeight) {
      ratio = maxHeight / height;
      width = width * ratio;
      height = height * ratio;
    }
      

    canvasContainer.current.style.height = `${height}px`;
    canvasContainer.current.style.width = `${width}px`;


    return { 
      width, 
      height
    };
  };

  const renderFilters = (canvas) => {
    window.Caman(canvas, function () {
      /**
       * Since filter paramterers indicate change amount (i.e. +5 or -5) and not new value,
       * we need to reset filters before applying them again by indicated amount in order to enable
       * use of sliders
       */
      this.revert(false);
      Object.keys(photo.properties).forEach((key) => {
        if (typeof this[key] === "function") {
          this[key](photo.properties[key]);
        }
      });
      if (photo.filter && photo.filter !== "" && photo.filter !== "original") {
        this.newLayer(function () {
          this.opacity(photo.properties.intensity);
          this.copyParent();
          this.filter[photo.filter]();
        });
      }
      this.render(function() {
        canvas.getContext('2d').drawImage(watermark, canvas.width - watermark.width - 8, 8);
      });
    });
  };

  const sharePhoto = async () => {
    let file = canvas.current.toDataURL("image/jpeg", 0.8);
    if (currentEdit && currentEdit.file === file) {
      return currentEdit.shareLink; // in order not to upload same file mor than once
    }
    let fileName = await communication.uploadPhoto(file);
    let shareLink = `https://phototopia.co/shared/${btoa(`https://dwuad47tp6qws.cloudfront.net/${fileName}`)}`;
    setCurrentEdit({file, shareLink});
    return shareLink;
  }

  if (!photo.url) {
    return null;
  }

  return (
    <div id="editor-container" ref={editorContainer}>
      <div id="canvas-container" ref={canvasContainer}>
        <canvas id="originalPhoto" ref={originalPhotoCanvas} />
        <canvas id="uploadedPhoto" ref={canvas} />
      </div>
      <div id="photo-controls">
        <UploadButton
          text="Change Photo"
          colorClass="gray"
        />
        
        <Download />
        <Share callback={sharePhoto} />
        <div
          className="photo-control-button control-button gray-button"
          onMouseDown={showOriginal}
          onMouseUp={returnState}
        >
          <span className="photo-control-label" id="photo-control-see-original">
            See Original
          </span>
        </div>
      </div>
    </div>
    // Add spot
  );
};

const mapStateToProps = (state) => ({
  lightboxState: state.lightbox,
});

export default connect(mapStateToProps, { })(Photo);
