import React from "react";
import { connect } from "react-redux";
import { copyUrl } from "../utils/IO";
import { openLightbox, changeActiveLightbox } from "../actions/lightboxActions";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { trackAction } from "../utils/tracking";
import { EVENT_CATEGORY, EVENT_ACTION, EVENT_LABEL } from "../utils/mappings";

export const Share = ({ callback, openLightbox, changeActiveLightbox }) => {
  let handleClick = async () => {
    trackAction(EVENT_CATEGORY.IMAGE, EVENT_ACTION.SHARE);
    const shareHandler = (label) => {
      trackAction(EVENT_CATEGORY.IMAGE,EVENT_ACTION.SHARE, label);
    }
    openLightbox({
      active: true,
      title: "Share photo",
      type: "share",
      closable: false,
      period: 3,
      loadingMessage: "Generating share links starts in",
      message: "Generating share links in progres...",
    });

    const copyHandler = () => {
      trackAction(EVENT_CATEGORY.IMAGE, EVENT_ACTION.SHARE, EVENT_LABEL.COPY_LINK);
      copyUrl(url);
      var node = document.createElement("div");
      var textnode = document.createTextNode("Copied!");
      node.appendChild(textnode);
      node.className = "copied-notification";
      document.getElementsByClassName("lbmain")[0].appendChild(node);
      setTimeout(
        () => document.getElementsByClassName("lbmain")[0].removeChild(node),
        800
      );
    };
    let url = await callback();
    let footer = (
      <div className="share-footer">
        <input className="share-url" type="url" value={url} />
        <div className="share-footer-buttons-group">
          <FacebookShareButton
            url={url}
            windowWidth={550}
            windowHeight={400}
            quote={"Try phototopia filters yourself!"}
            onClick={() => shareHandler(EVENT_LABEL.FACEBOOK)}
          >
            <div className="share-button share-button-facebook">
              <img
                src="https://drjl6ytj0fv0u.cloudfront.net/images/share_fb.svg"
                alt="share on facebook"
              />
              Facebook
            </div>
          </FacebookShareButton>

          <TwitterShareButton
            url={url}
            windowWidth={550}
            windowHeight={400}
            title={"Try phototopia filters yourself!"}
            onClick={() => shareHandler(EVENT_LABEL.TWITTER)}
          >
            <div className="share-button share-button-twitter">
              <img
                src="https://drjl6ytj0fv0u.cloudfront.net/images/share_tw.svg"
                alt="share on twitter"
              />
              Twitter
            </div>
          </TwitterShareButton>

          <div className="share-button share-button-copy" onClick={copyHandler}>
            Copy link
          </div>
        </div>
      </div>
    );

    changeActiveLightbox({
      period: 0,
      message: null,
      callback: null,
      type: "share",
      footer,
    });
  };

  return (
    <div
      className="photo-control-button control-button cyan-button"
      onClick={handleClick}
    >
      <span className="photo-control-label">Share</span>
    </div>
  );
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { openLightbox, changeActiveLightbox })(
  Share
);
