import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from './reducers';
import localforage from 'localforage';

const initialState = {};

const middleware = [thunk];

export var store = null;
export var persistor = null;

export const configureStoreInitialStateAsync = () => {
  return new Promise(async resolve => {
    const persistConfig = {
      key: `phototopia-storage`,
      storage,
      blacklist: ['photo']
    };

    localforage.config({
      driver: [
          localforage.INDEXEDDB,
          localforage.WEBSQL,
          localforage.LOCALSTORAGE
      ],
      name: 'phototopia'
    });

    store = createStore(
      persistReducer(persistConfig, rootReducer),
      initialState,
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? compose(
            applyMiddleware(...middleware),
            window.__REDUX_DEVTOOLS_EXTENSION__()
          )
        : applyMiddleware(...middleware)
    );

    persistor = persistStore(store);
    resolve();
  });
};
