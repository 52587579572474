import React from "react";
import { withRouter, Link } from "react-router-dom";
import Logo from "./Logo";
// import FacebookLikeWidget from "./FacebookLikeWidget";

const Header = () => {
  return (
    <div className="header">
      <Link to="/">
        <Logo />
      </Link>
      {/* <FacebookLikeWidget /> */}
    </div>
  );
};

export default withRouter(Header);
