import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { trackAction } from "../utils/tracking";
import communication from "../utils/communication";
import { EVENT_ACTION, EVENT_CATEGORY } from "../utils/mappings";
import { updateReleaseNotes } from "../actions/releaseNotesActions";

const ReleaseNotes = ({ latestUpdates, updateReleaseNotes }) => {
  const [closed, setClosed] = useState(true);

  useEffect(() => {
    communication.getReleaseNotes().then((data) => {
      if (data.date === latestUpdates.date) 
        return;
      updateReleaseNotes(data);
      setClosed(false);
    });
  }, [latestUpdates, updateReleaseNotes]);

  const handleClose = () => {
    setClosed(true);
  };

  const handleClick = () => {
    trackAction(EVENT_CATEGORY.USER_RELATIONSHIP, EVENT_ACTION.FEEDBACK);
    window.open("https://airtable.com/shrJOm9R0opTZU2Sa");
  };

  return closed ? null : (
    <div className="dimmer">
      <div class="feedback-close-button" onClick={handleClose} />
      <div class="feedback">
        <div>We are working hard to improve your experience!</div>
        <div class="release-notes">
          <h4>Latest Updates:</h4>
          <ul id="release-notes-list">
            {latestUpdates &&
              latestUpdates.notes.map((i, key) => (
                <li key={key} style={{ textAlign: "start" }}>
                  {i}
                </li>
              ))}
          </ul>
        </div>
        <div
          className="feedback-cta magenta-button control-button"
          onClick={handleClick}
        >
          Help Us Improve
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  latestUpdates: state.releaseNotes,
});

export default connect(mapStateToProps, { updateReleaseNotes })(ReleaseNotes);
