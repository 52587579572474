const getFileFromFileReader = (e) => {
  return new Promise((resolve, reject) => {
    let files = e.target.files ? e.target.files : e.dataTransfer.files;
    let file = files && files[0];

    let reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.addEventListener(
        "load",
        () => {
          resolve({
            fileName: file.name,
            fileContents: reader.result,
          });
        },
        false
      );
    }
  });
};

const copyUrl = (url) => {
  const tmp = document.createElement("input");
  document.body.appendChild(tmp);
  // tmp.style.visibility = 'hidden';
  tmp.value = url;
  tmp.select();
  document.execCommand("copy");
  document.body.removeChild(tmp);
};

export { getFileFromFileReader, copyUrl };
