import { INIT_LIGHTBOX } from "./types";

export const openLightbox = (lightbox) => (dispatch) => {
  dispatch({
    type: INIT_LIGHTBOX,
    payload: lightbox,
  });
};

export const changeActiveLightbox = (lightbox) => (dispatch) => {
  dispatch({
    type: INIT_LIGHTBOX,
    payload: lightbox,
  });
};

export const turnOffLightbox = () => (dispatch) => {
  dispatch({
    type: INIT_LIGHTBOX,
    payload: {
      active: false,
      title: null,
      closable: false,
      close: false,
      period: 0,
      loadingMessage: null,
      message: null,
      callback: null,
      addTag: null,
      footer: null,
    },
  });
};
