export const trackAction = (category, action, label) => {
  const trackInterval = setInterval(() => {
    if (!window.ga) 
      return;
      let tracker = window.ga.getAll()[0];
      window.ga(() => {
        tracker.send("event", category, action, label);
      });
    clearInterval(trackInterval);
  }, 100);
};
