import React, { useEffect, useRef } from "react";
import { useParams } from "react-router";
import UploadPhoto from "../components/UploadPhoto";
import Photo from "../components/Photo";
import Lightbox from "../components/Lightbox";
import Filters from "../components/Filters";
import { setPhotoUrl, setFileName, setFilter, resetFilters } from "../actions/photoActions";
import ImagePropertyControls from '../components/ImagePropertyControls';
import { connect } from "react-redux";
import communication from '../utils/communication';
import localforage from 'localforage';
import { getAdTag } from "../utils/adverts";
import Advert from "../components/Advert";

const PhotoFilter = ({ setPhotoUrl, setFileName, setFilter, resetFilters, photo }) => {
  let { url } = useParams();

  useEffect(() => {
    const decodedUrl = url && atob(decodeURIComponent(url));

    if (decodedUrl) {
      const nameArr = decodedUrl.split("/");

      setPhotoUrl(`https://${communication.API_SERVER_URL}/proxy/${url}`);
      setFileName(nameArr[nameArr.length - 1]);
    } else {
      localforage.getItem('uploaded-photo').then(uploadedPhoto => {
        if (!uploadedPhoto) {
          return;
        }
        setPhotoUrl(uploadedPhoto.file);
        setFileName(uploadedPhoto.name);

        localforage.removeItem('uploaded-photo').catch(console.error);
      }).catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeFilterOption = event => {
    const filter = event.target.dataset.filter;
    const value = event.target.dataset.value;

    setFilter(filter, value);
  }


  let uploadPhotoComponent = <div style={{marginBottom: '15vh'}}><UploadPhoto /></div>;
  const controlsDimmer = useRef(null);
  if (photo && photo.url) {
    uploadPhotoComponent = null;
    controlsDimmer.current.style.display = 'none';
  }
  return (
    <div className="main">
      <div className="left">
        <Filters callback={changeFilterOption} activeFilter={photo.filter} />
        <ImagePropertyControls callback={changeFilterOption} properties={photo.properties} />
        <div id='controls-dimmer' ref={controlsDimmer}></div>
      </div>
      <div className="editor">
        {uploadPhotoComponent}
        <Photo photo={photo} />
        <Advert adTag={getAdTag('main')} />
      </div>
      <Lightbox />
    </div>
  );
};

const mapStateToProps = (state) => ({
  photo: state.photo
});

export default connect(mapStateToProps, { setPhotoUrl, setFileName, setFilter, resetFilters })(
  PhotoFilter
);
