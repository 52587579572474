import React from 'react'
import ImagePropertyControl from "../components/ImagePropertyControl";

const ImagePropertyControls = ({callback, properties}) => {

  /**
   * When adding new property, add
   */
  const propertiesList = [
    {
      id: 'intensity',
      name: 'Filter Intensity',
      minValue: 0,
      maxValue: 100,
      colored: true
    },
    {
      id: 'brightness',
      name: 'Brightness',
      minValue: -100,
      maxValue: 100,
    },
    {
      id: 'contrast',
      name: 'Contrast',
      minValue: -100,
      maxValue: 100,
    },
    {
      id: 'sharpen',
      name: 'Sharpen',
      minValue: 0,
      maxValue: 100,
    },
    {
      id: 'saturation',
      name: 'Saturation',
      minValue: -100,
      maxValue: 100,
    },
    {
      id: 'vibrance',
      name: 'Vibrance',
      minValue: -100,
      maxValue: 100,
    },
    {
      id: 'exposure',
      name: 'Exposure',
      minValue: -100,
      maxValue: 100,
    },

  ]

    return (
        <div className="image-controls-container container-scrollbar">
          <div className="image-controls">
            {propertiesList.map((propertyControl, i) => (
              <ImagePropertyControl key={i}
                callback={callback}
                name={propertyControl.name}
                property={propertyControl.id}
                initialValue={properties[propertyControl.id]} // Reading from store
                minValue={propertyControl.minValue}
                maxValue={propertyControl.maxValue}
                {...propertyControl} // Add all other props
              />
            ))}
          </div>
        </div>
    )
}

export default ImagePropertyControls
