import React from "react";
import { Filter } from "./Filter";

export const Filters = ({ callback, activeFilter }) => {
  // let Caman = window.Caman; //change !!!
  // let canvasId = "#uploadedPhoto";

  const basicFilters = [
    {
      id: 'original',
      label: 'Original'
    },
    { 
      id: 'vintage', 
      label: 'Vintage' 
    }, 
    { 
      id: 'lomo', 
      label: 'Lomo' 
    }, 
    { 
      id: 'clarity', 
      label: 'Clarity' 
    },
    { 
      id: 'sinCity',
      label: 'Sin City' 
    }, 
    { 
      id: 'crossProcess',
      label: 'Cross Process' 
    }, 
    { 
      id: 'pinhole',
      label: 'Pinhole' 
    },
    { 
      id: 'nostalgia',
      label: 'Nostalgia' 
    }, 
    { 
      id: 'herMajesty',
      label: 'Her Majesty' 
    },
    {
      id: 'oldBoot',
      label: 'Old Boot'
    }
  ];
  const customFilters = [];

  // let filterList = basicFilters.map(filter => {
  //   return {
  //     callback: () => {
  //       let img = document.getElementById('original-image');
  //       Caman(canvasId, img, function () {
  //         this.reset();
  //         this[filter.id]().render();
  //       });
  //     },
  //     id: filter.id,
  //     text: filter.label
  //   }
  // });

  const filterList = [...basicFilters, ...customFilters]

  return (
    <div className="filter-list-container container-scrollbar">
      <div className="filter-list">
        {filterList.map((filter, i) => (
          <Filter key={i} callback={callback} text={filter.label} filter={filter.id} active={activeFilter === filter.id} />
        ))}
      </div>
    </div>
  );
};

export default Filters;
