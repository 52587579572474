import React, { useState } from "react";
import UploadButton from './UploadButton';
import { setPhotoUrl, setFileName } from "../actions/photoActions";
import { connect } from "react-redux";
import { getFileFromFileReader } from '../utils/IO';
import { isInIFrame } from "../utils/iframe";
import { trackAction } from "../utils/tracking";
import { EVENT_CATEGORY, EVENT_ACTION } from "../utils/mappings";

export const UploadPhoto = ({ setPhotoUrl, setFileName, callback = () => {} }) => {
  const [hovered, setHovered] = useState(false);
  const [enteredCounter, setEnteredCounter] = useState(0);

  const DragDropEvents = {
    dragenter: (e) => {
      setEnteredCounter(enteredCounter + 1);
      setHovered(true);
    },
    dragleave: (e) => {
      setEnteredCounter(enteredCounter - 1);
      setHovered(enteredCounter - 1 === 0 ? false : true);
    },
    drop: (e) => {
      handleImageUpload(e);
      setEnteredCounter(0);
      setHovered(false);
    }
  }

  const onFileDragDropDispatcher = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (-1 === Object.getOwnPropertyNames(DragDropEvents).indexOf(e.type))
      return;
    DragDropEvents[e.type](e);
  }

  const handleImageUpload = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const fileInfo = await getFileFromFileReader(e);
    console.log({fileInfo})
    if (typeof setFileName === 'function' && typeof setPhotoUrl === 'function') {
      setFileName(fileInfo.fileName);
      setPhotoUrl(fileInfo.fileContents);
    }
    callback(fileInfo.fileContents, fileInfo.fileName);
    if(!isInIFrame())
      return;
    trackAction(EVENT_CATEGORY.POPUP, EVENT_ACTION.IMAGE_UPLOAD);
  }

  return (
    <div id="image-upload-container" style={{background: hovered ? "#c2c2c240" : "#0000"}}>
      <div id="image-upload-area"
        onDragEnter={onFileDragDropDispatcher}
        onDragOver={onFileDragDropDispatcher}
        onDragLeave={onFileDragDropDispatcher}
        onDrop={onFileDragDropDispatcher}>
        <span className='image-upload-label'>
          Drag image here or
      </span>
      <UploadButton text='Upload' callback={callback}/>
    </div>
  </div>);
};

const mapStateToProps = state => {
  return {
    photo: state.photo
  };
};
export default connect(
  mapStateToProps,
  { setPhotoUrl, setFileName }
)(UploadPhoto);
