import React from "react";
import { UploadPhoto } from "../components/UploadPhoto";
import Advert from "../components/Advert";
import localforage from "localforage";
import { instashareAdvert } from "../utils/adverts";
import ReleaseNotes from "../components/ReleaseNotes";

export const BAB = () => {
  const WEBSITE_URL = "https://phototopia.co/";

  const openNewTab = (file, name) => {
    localforage.setItem("uploaded-photo", { file, name }).then(() => {
      window.open(WEBSITE_URL, "_blank");
    });
  };

  return (
    <div id="bab" className="bab-container">
      <UploadPhoto callback={openNewTab} />
      <ReleaseNotes/>
      <Advert adTag={instashareAdvert}></Advert>
    </div>
  );
};

export default BAB;
