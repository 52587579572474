export const EVENT_CATEGORY = {
    WIDGET: "Widget",
    POPUP: "Popup",
    ADS_BANNER: "Ads Banner",
    USER_RELATIONSHIP: "User Relationship",
    FILTER: "Filter",
    IMAGE: "Image",
    SHARE_PAGE: "Share Page"
}

export const EVENT_ACTION = {
    IMAGE_SELECT: "Image Select",
    IMAGE_UPLOAD: "Image Upload",
    IMPPRESSION: "Impression",
    CLICK: "Click",
    FEEDBACK: "Feedback",
    LOGO: "Logo",
    CONTACT: "Contact",
    TERMS: "Terms",
    PRIVACY: "Privacy",
    ABOUT: "About",
    SELECT: "Select",
    SLIDER: "Slider",
    CLICK_SEE_ORIGINAL: "See Original",
    DOWNLOAD: "Download",
    SHARE: "Share",
    TRY_IT: "Try It",
    UPLOAD: "Upload",
    CHANGE: "Change"
}

export const EVENT_LABEL = {
    FACEBOOK: "Facebook",
    TWITTER: "Twitter",
    COPY_LINK: "Copy Link"
}