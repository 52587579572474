import { UPDATE_RELEASE_NOTES } from "../actions/types";

const initialState = {
  date: "2020-05-10",
  notes: [],
};

const releaseNotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_RELEASE_NOTES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default releaseNotesReducer;
