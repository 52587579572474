import React from "react";
import { setPhotoUrl, setFileName } from "../actions/photoActions";
import { openLightbox } from "../actions/lightboxActions";
import { getFileFromFileReader } from "../utils/IO";
import { connect } from "react-redux";
import { EVENT_CATEGORY, EVENT_ACTION } from "../utils/mappings";
import { trackAction } from "../utils/tracking";
import { isInIFrame } from "../utils/iframe";

const UploadButton = ({
  setPhotoUrl,
  setFileName,
  text,
  colorClass = "magenta",
  callback = () => {},
  openLightbox,
}) => {
  const onInput = async (e) => {
    openUploadLightbox();
    const fileInfo = await getFileFromFileReader(e);
    setFileName(fileInfo.fileName);
    setPhotoUrl(fileInfo.fileContents);
    callback(fileInfo.fileContents, fileInfo.fileName);
    isInIFrame() ? trackAction(EVENT_CATEGORY.POPUP, EVENT_ACTION.IMAGE_UPLOAD) : trackAction(EVENT_CATEGORY.IMAGE, text.startsWith("Change") ? EVENT_ACTION.CHANGE : EVENT_ACTION.UPLOAD);
  };

  const openUploadLightbox = () => {
    openLightbox({
      active: true,
      title: "Upload photo",
      closable: false,
      close: true,
      period: 3,
      loadingMessage: "Upload photo in",
      type: 'upload'
    });
  };

  return (
    <label className={`upload-button control-button ${colorClass}-button`}>
      <input
        className="image-upload-input"
        type="file"
        name="files[]"
        accept="image/*;"
        onInput={onInput}
      />
      <span className="upload-button-label"> {text} </span>
    </label>
  );
};

const mapStateToProps = (state) => ({
  photo: state.photo,
});

export default connect(mapStateToProps, {
  setPhotoUrl,
  setFileName,
  openLightbox,
})(UploadButton);
