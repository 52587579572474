import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import PhotoFilter from "./pages/PhotoFilter";
import BAB from "./pages/BAB";
import SharedPhoto from "./pages/SharedPhoto";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./css/Layout.css";

const App = () => {
  return (
    <div className="container">
      <Router>
        <Header />
        <Switch>
          <Route exact path="/shared/:url" component={SharedPhoto} />
          <Route exact path="/bab" component={BAB} />
          <Route exact path="/:url?" component={PhotoFilter} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
