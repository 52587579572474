import React, { useState, useEffect, useRef } from 'react';
import { trackAction } from '../utils/tracking';
import { EVENT_CATEGORY, EVENT_ACTION } from '../utils/mappings';

const ImagePropertyControl = ({ callback, name, property, initialValue = 50, minValue = 0, maxValue = 100, colored = false }) => {
    const [value, setValue] = useState(initialValue);
    const slider = useRef(null);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const onValueUpdated = (e) => {
        const val = e.currentTarget.value;
        setValue(val);
        if (colored) {
            slider.current.style.background = `linear-gradient(to right, #02fb8d, #00aeda ${val}%, #252525 ${val}%)`;
        }
        trackAction(EVENT_CATEGORY.FILTER, EVENT_ACTION.SLIDER, name);
    }
    
    return (<div className='slider-container'>
        <div className='slider-meta'>
            <span className='slider-label'>{name}</span>
            <span className='slider-value'>{value}</span>
        </div>
        <input type='range' className={`slider${colored ? ' colored' : ''}`} ref={slider}
            min={minValue} max={maxValue} value={value} onChange={onValueUpdated} 
            data-filter={property.toLowerCase()} data-value={value} onMouseUp={callback} />
    </div>)
}

export default ImagePropertyControl;