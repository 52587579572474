import React from 'react';
import PhototopiaLogo from '../images/phototopia_logo.png'
import { trackAction } from '../utils/tracking';
import { EVENT_ACTION, EVENT_CATEGORY } from '../utils/mappings';
const Logo = () => {

    const handleClick = () => {
        trackAction(EVENT_CATEGORY.USER_RELATIONSHIP, EVENT_ACTION.LOGO)
    };

    return (<div className="phototopia-logo" onClick={handleClick}>
        <img src={PhototopiaLogo} alt="phototopia"></img>
    </div>);
}

export default Logo;