import { INIT_LIGHTBOX } from "../actions/types";

const initialState = {
  active: false,
  title: "",
  closable: true,
  addTag: null,
  loadingMessage: "",
  period: 0,
  message: "",
  footer: null,
  callback: null,
};

const lightboxReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_LIGHTBOX: 
      return {...state, ...action.payload};
    default:
      return state;
  }
};

export default lightboxReducer;


