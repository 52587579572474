import React, { useEffect } from "react";
import { isInIFrame } from "../utils/iframe";
import { trackAction } from "../utils/tracking";
import { EVENT_CATEGORY, EVENT_ACTION } from "../utils/mappings";

export const Advert = ({ adTag }) => {
  useEffect(() => {
    if (!isInIFrame()) return;
    const trackImpression = setInterval(() => {
      if (!window.ga) return;
      trackAction(
        EVENT_CATEGORY.ADS_BANNER,
        EVENT_ACTION.IMPPRESSION,
        adTag.props.id
      );
      clearInterval(trackImpression);
    }, 100);
  }, [adTag.props.id]);

  const handleClick = () => {
    if (!isInIFrame()) return;
    trackAction(EVENT_CATEGORY.ADS_BANNER, EVENT_ACTION.CLICK, adTag.props.id);
  };

  return <div onClick={handleClick}>{adTag}</div>;
};

export default Advert;
