import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { setPhotoUrl, setFileName } from "../actions/photoActions";
import { turnOffLightbox } from "../actions/lightboxActions";
import Advert from "../components/Advert";
import { getAdTag, enablAdTag } from '../utils/adverts';
import { trackAction } from "../utils/tracking";
import { EVENT_CATEGORY, EVENT_ACTION } from "../utils/mappings";

export const SharedPhoto = ({photo, setPhotoUrl, colorClass = "cyan",setFileName}) => {
  const WEBSITE_URL = "https://phototopia.co";
  const { url } = useParams();

  useEffect(() => {
    enablAdTag('tall');
    enablAdTag('wide');
    let decodedUrl = url && atob(decodeURIComponent(url));
    if (decodedUrl) {
      setPhotoUrl(decodedUrl);
      // let nameArr = decodedUrl.split("/");
      // setFileName(nameArr[nameArr.length - 1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const openWebsite = () => {
    trackAction(EVENT_CATEGORY.SHARE_PAGE, EVENT_ACTION.TRY_IT);
    turnOffLightbox();
    window.open(WEBSITE_URL, "_blank")
  }

  return (
    <div className="shared">
      <div className="top">
      <label>Like this photo filter?</label>
        <div className="control-button cyan-button">
          <span className="photo-control-label" onClick={openWebsite}>Try it</span>
        </div>
      </div>
      <div className="leftshare"></div>
      <div className="center">
        <img className="photo" src={photo.url} alt="powered by phototopia"></img>
      </div>
      <div className="right"><Advert adTag={getAdTag('share_page_tall')}></Advert></div> 
      <div className="bottom"><Advert adTag={getAdTag('share_page_wide')}></Advert></div>
    </div>
  );
};

const mapStateToProps = state => ({
  photo: state.photo
});

export default connect(
  mapStateToProps,
  { setPhotoUrl, setFileName }
)(SharedPhoto);
