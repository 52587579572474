import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import App from "./App";

import { persistor, store, configureStoreInitialStateAsync } from "./store";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

configureStoreInitialStateAsync().then(() => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
    document.getElementById("root")
  );
});
