import React from "react";
import { connect } from "react-redux";
import { openLightbox } from "../actions/lightboxActions";
import { trackAction } from "../utils/tracking";
import { EVENT_CATEGORY, EVENT_ACTION } from "../utils/mappings";

export const Download = ({ fileName, openLightbox }) => {
  const downloadPhoto = () => {
    const newFilename = `${fileName.replace(/\.\w{3,4}(\?.*)?/, '')}-edited.jpg`
    download(document.getElementById("uploadedPhoto"), newFilename);
  };

  const download = (canvas, filename) => {
    const link = document.createElement("a");
    link.download = filename;
    link.href = canvas.toDataURL("image/jpeg", 0.8);
    link.dispatchEvent(new MouseEvent("click"));
  };

  let openDownloadLightbox = () => {
    openLightbox({
      active: true,
      title: "Download photo",
      type: 'download',
      closable: false,
      period: 4,
      loadingMessage: "Download will start in",
      message: "Enjoy Phototopia filters!",
      callback: downloadPhoto
    })
    trackAction(EVENT_CATEGORY.IMAGE, EVENT_ACTION.DOWNLOAD);
  }

  return (
    <div className='photo-control-button control-button magenta-button'
      onClick={openDownloadLightbox}>
      <span className='photo-control-label'>Download</span>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    fileName: state.photo.fileName
  };
};

export default connect(
  mapStateToProps,
  { openLightbox }
)(Download);
