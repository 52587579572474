import React from "react";
import { trackAction } from "../utils/tracking";
import { EVENT_ACTION, EVENT_CATEGORY } from '../utils/mappings'

function Footer() {

  return (
    <div className="footer">
      <div className="footer-button-group">
      <a id="privacy" href="/privacy-policy" rel="noopener noreferrer">Privacy Policy</a>
      <a id="copyright" href="/copyright-policy" rel="noopener noreferrer">Copyright Policy</a>
      <a id="terms" href="/terms-of-use" rel="noopener noreferrer">Terms of Use</a>
      <a id="uninstall" href="/uninstall" rel="noopener noreferrer">Uninstall</a>
      <a id="opt-out-request" href="/opt-out-request" rel="noopener noreferrer">Do Not Sell My Personal Info</a>
      <a id="contact" href="/contact" rel="noopener noreferrer">Contact</a>
    </div>
    </div>
  );
}

export default Footer;
