import { SET_PHOTO, SET_FILENAME, SET_FILTER, RESET_FILTERS } from "./types";

export const setPhotoUrl = url => dispatch => {
    dispatch({
        type: SET_PHOTO,
        payload: url
    })
}

export const setFileName = name => dispatch => {
    dispatch({
        type: SET_FILENAME,
        payload: name
    })
}

export const setFilter = (name, value) => dispatch => {
    if (name === 'filter') {
        dispatch({
            type: SET_FILTER,
            payload: {
                filter: value
            }
        })
        return
    }
    const payload = {}
    payload[name] = Number.parseInt(value)

    dispatch({
        type: SET_FILTER,
        payload
    })
}

export const resetFilters = () => dispatch => {
    dispatch({
        type: RESET_FILTERS
    })
}