
export default {
  API_SERVER_URL: "https://api.phototopia.co",

  uploadPhoto(photo) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", photo);
      return fetch(`${this.API_SERVER_URL}/upload`, {
        method: "PUT",
        body: formData
      })
        .then(response => {
          response
            .json()
            .then(data => {
              if (data.error) {
                reject(data);
              } else {
                resolve(data.photoName);
              }
            })
            .catch(() => reject());
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  async getReleaseNotes() {
    const url = `${this.API_SERVER_URL}/release`;
    let response = await fetch(url);
    return await response.json();
  }
};
