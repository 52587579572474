import React from "react";
import { trackAction } from "../utils/tracking";
import { EVENT_CATEGORY, EVENT_ACTION } from "../utils/mappings";

const filterImages = require.context('../images/filters/', false);

export const Filter = ({ callback, text, filter, active = false }) => {
  const clickHandler = event => {
    callback(event);
    trackAction(EVENT_CATEGORY.FILTER, EVENT_ACTION.SELECT, text);
  }
  return (
    <div className={`image-filter${active ? ' active' : ''}`} onClick={clickHandler}>
      <img data-filter='filter' data-value={filter} src={filterImages(`./${filter}.jpg`)} alt={filter} />
      <div className='shadow' data-filter='filter' data-value={filter}></div>
      <span className='filter-text' data-filter='filter' data-value={filter}>{text}</span>
    </div >
  );
};

export default Filter;
