import { SET_PHOTO, SET_FILENAME, SET_FILTER, RESET_FILTERS } from "../actions/types";

const initialState = {
  filter: 'original',
  properties: {
    intensity: 100,
    brightness: 0,
    contrast: 0,
    sharpen: 0,
    saturation: 0,
    vibrance: 0,
    exposure: 0
  },
  url: null
};

const photoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PHOTO:
     return  {
        ...state,
        url: action.payload
      };
    case SET_FILENAME:
      return {
        ...state,
        fileName: action.payload
      };
    case SET_FILTER:
      if (action.payload.filter) {
        return {
          ...state,
          ...action.payload
        }
      }
      return {
        ...state,
        properties: {
          ...state.properties,
          ...action.payload
        }
      }
    case RESET_FILTERS:
      return {
        ...state,
        filter: 'original',
        properties: { 
          ...initialState.properties
        }
      }
    default:
      return state;
  }
};

export default photoReducer;


