import React, { useState, useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { turnOffLightbox } from "../actions/lightboxActions";
import img from "../images/drop_area_bg.png";
import "../css/Layout.css";
import Advert from './Advert'
import { getAdTag } from "../utils/adverts";

export const Lightbox = ({ lightboxState, turnOffLightbox }) => {
  const [close, setClose] = useState(lightboxState.closable);
  const [footer, setFooter] = useState(null);
  const [countDown, update] = useReducer((state, action) => {
    return action ? lightboxState.period : state ? state - 1 : state;
  }, 0);

  useEffect(() => {
    let a = setInterval(update, 1000);
    return () => {
      turnOffLightbox();
      clearInterval(a);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setClose(lightboxState.closable);
    setFooter(lightboxState.footer);
    update(true);
    setTimeout(() => {
      setClose(true);
      lightboxState.close && turnOffLightbox();
      lightboxState.callback && lightboxState.callback();
    }, lightboxState.period * 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lightboxState]);

  return lightboxState.active ? (
    <div className="dimmer">
      <div className="lightbox">
        <div className="lbheader">
          <span>{lightboxState.title}</span>
          {close ? <span onClick={() => turnOffLightbox()}>x</span> : null}
        </div>
        <div className="lbmain">
          {<Advert adTag={getAdTag(lightboxState.type)} /> || <img src={img} alt="Ad place"/>}
          <span>
            {countDown
              ? `${lightboxState.loadingMessage} ${countDown}s`
              : lightboxState.message}
          </span>
          {!!countDown || footer}
        </div>
      </div>
    </div>
  ) : null;
};

const mapStateToProps = (state) => ({
  lightboxState: state.lightbox,
});

export default connect(mapStateToProps, { turnOffLightbox })(
  Lightbox
);
