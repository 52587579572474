import React from "react";

const adTypes = {
    main: 'div-gpt-ad-1588891133499-0',
    upload: 'div-gpt-ad-1588892062163-0',
    download: 'div-gpt-ad-1588891044766-0',
    share: 'div-gpt-ad-1588891238041-0',
    share_page_tall: 'div-gpt-ad-1588891879017-0',
    share_page_wide: 'div-gpt-ad-1588891958287-0',
}

export const getAdTag = (type) => {
    return (
        <div id={adTypes[type]}>
            {enablAdTag(type)}
        </div>
    );
}

export const enablAdTag = (type) => {
    window.googletag.cmd.push(() => window.googletag.display(adTypes[type])) 
}

export const instashareAdvert = (
    // eslint-disable-next-line
    <a id="instashare-advert" onClick={() => window.open("https://chrome.google.com/webstore/detail/instashare/fdgfhjoffpdepaofkhaneilbholahlhc", "_blank")}>
        <img src="./instashareLogo.svg" alt="advert"/>
        <img src="./instashareAppName.svg" alt="advert"/>
    </a>
);

